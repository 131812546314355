'use client';

import { useGlobalContext } from '@/react/hooks/useGlobalContext';
import { useRouter } from '@/react/utils/router-utils/useRouter';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState
} from 'react';
import { Spinner } from '../../core-ui/Spinner';

import S from './styles.module.scss';

export interface ILoginBoundaryProps extends PropsWithChildren {
  /**
   * The component/element to show as a placeholder
   * when the boundary is still checking login status.
   *
   * Will default to a centered Spinner.
   */
  placeholder?: JSX.Element;

  /**
   * Specifies what should this boundary check for.
   *
   * - `loggedIn`: The content will only be accessible to logged-in users.
   *  If an anonymous/guest user tries to access the content, they will be redirected to the login page.
   *
   * - `notLoggedIn`: The content will only be accessible to anonymous/guest users.
   *   If a logged-in user tries to access the content, they will be redirected to the account page.
   *
   * Defaults to `loggedIn`.
   */
  mode?: 'loggedIn' | 'notLoggedIn';
}

/**
 * Wrapper component that gatekeeps content only available for logged-in users.
 * If the user is not logged in, they will be redirected to the configured login
 * URL instead.
 */
export const LoginBoundary: FunctionComponent<ILoginBoundaryProps> = ({
  children,
  placeholder,
  mode
}) => {
  const [entryAllowed, setEntryAllowed] = useState<boolean | null>(null);
  const router = useRouter();

  const { user } = useGlobalContext();

  useEffect(
    function handleLoginStatus() {
      (async () => {
        // If the user has not been fetched by the global provider yet, do not try to check their status.
        if (!user) {
          return;
        }

        const redirectURL =
          mode === 'notLoggedIn'
            ? ConfigurationService.getConfig('user').getSetting('accountURL')
                .value
            : ConfigurationService.getConfig('user').getSetting('loginURL')
                .value;

        const isUserLoggedIn = user?.isLoggedIn;

        const willEntryBeAllowed =
          mode === 'notLoggedIn' ? !isUserLoggedIn : isUserLoggedIn;

        if (!willEntryBeAllowed) router.push(redirectURL);
        else setEntryAllowed(true);
      })();
    },
    [user]
  );

  if (!entryAllowed) {
    return (
      placeholder ?? (
        <div className={S.placeholder}>
          <Spinner className={S.spinner} page />
        </div>
      )
    );
  }

  return <>{children}</>;
};
