'use client';

import { observer } from 'mobx-react-lite';

import { Button } from '@/react/components/core-ui/Button';
import { Checkbox } from '@/react/components/core-ui/Checkbox';
import { Link } from '@/react/components/core-ui/Link';
import { Spinner } from '@/react/components/core-ui/Spinner';
import { TextField } from '@/react/components/core-ui/TextField';
import { PhoneField } from '@/react/components/core-ui/TextField/PhoneField';
import { useFormContext } from '@/react/hooks/useFormContext';
import { EventType } from '@/services/isomorphic/UserInteractionService';

import { CreateAccountFormModel } from '@/react/view-models/forms/CreateAccount';

import { msg } from '@/services/isomorphic/I18NService';

import S from './styles.base.module.scss';
import { forms_field_firstName } from "@/lang/__generated__/ahnu/forms_field_firstName";
import { forms_field_lastName } from "@/lang/__generated__/ahnu/forms_field_lastName";
import { forms_field_email } from "@/lang/__generated__/ahnu/forms_field_email";
import { forms_field_password } from "@/lang/__generated__/ahnu/forms_field_password";
import { account_passwordHelp1 } from "@/lang/__generated__/ahnu/account_passwordHelp1";
import { account_passwordHelp2 } from "@/lang/__generated__/ahnu/account_passwordHelp2";
import { account_passwordHelp3 } from "@/lang/__generated__/ahnu/account_passwordHelp3";
import { account_passwordHelp4 } from "@/lang/__generated__/ahnu/account_passwordHelp4";
import { account_passwordHelp5 } from "@/lang/__generated__/ahnu/account_passwordHelp5";
import { forms_field_phone } from "@/lang/__generated__/ahnu/forms_field_phone";
import { account_phoneNumberHelp } from "@/lang/__generated__/ahnu/account_phoneNumberHelp";
import { forms_field_emailList } from "@/lang/__generated__/ahnu/forms_field_emailList";
import { checkout_privacyPolicy } from "@/lang/__generated__/ahnu/checkout_privacyPolicy";
import { login_createAccount } from "@/lang/__generated__/ahnu/login_createAccount";

/**
 * The fields used for the create account form. This is separate in order
 * to allow the context.
 */
export const CreateAccountFormFields = observer(
  function CreateAccountFormFields() {
    const { form, isLoading } = useFormContext(CreateAccountFormModel);

    return (
      <>
        <TextField
          className={S.input}
          label={msg(forms_field_firstName)}
          fullWidth
          fieldName="firstName"
          datatestID="accFirstName"
          type="text"
          formModel={form}
          enterInteractionDetails={{
            action: EventType.FormInput,
            formName: 'create account',
            formFieldName: 'firstName'
          }}
        />
        <TextField
          className={S.input}
          label={msg(forms_field_lastName)}
          fullWidth
          fieldName="lastName"
          datatestID="accLastName"
          type="text"
          formModel={form}
          enterInteractionDetails={{
            action: EventType.FormInput,
            formName: 'create account',
            formFieldName: 'lastName'
          }}
        />
        <TextField
          className={S.input}
          label={msg(forms_field_email)}
          fullWidth
          fieldName="email"
          datatestID="accEmail"
          type="email"
          formModel={form}
          enterInteractionDetails={{
            action: EventType.FormInput,
            formName: 'create account',
            formFieldName: 'email'
          }}
        />
        <TextField
          className={S.input}
          label={msg(forms_field_password)}
          autoComplete="new-password"
          fullWidth
          fieldName="password"
          datatestID="accPassword"
          type="password"
          showablePassword
          formModel={form}
          enterInteractionDetails={{
            action: EventType.FormInput,
            formName: 'create account',
            formFieldName: 'password'
          }}
        />
        <div className={S.passwordDetails}>
          <ul>
            <li>{msg(account_passwordHelp1)}</li>
            <li>{msg(account_passwordHelp2)}</li>
            <li>{msg(account_passwordHelp3)}</li>
            <li>{msg(account_passwordHelp4)}</li>
            <li>{msg(account_passwordHelp5)}</li>
          </ul>
        </div>
        <PhoneField
          className={S.input}
          label={msg(forms_field_phone)}
          fullWidth
          fieldName="phone"
          datatestID="accPhone"
          type="phone"
          formModel={form}
          enterInteractionDetails={{
            action: EventType.FormInput,
            formName: 'create account',
            formFieldName: 'phone'
          }}
        />
        <p className={S.phoneExample}>{msg(account_phoneNumberHelp)}</p>
        <div className={S.emailList}>
          <Checkbox
            id="emailList"
            formModel={form}
            fieldName="emailList"
            datatestID="accEmailList"
            label={
              <span>
                {`${msg(forms_field_emailList)} `}
                <Link variant="link" href="/privacy-policy" openInNewTab>
                  {msg(checkout_privacyPolicy)}
                </Link>
              </span>
            }
          />
        </div>
        <Button
          fullWidth
          className={S.submitButton}
          disabled={isLoading}
          datatestID="accSubmit"
          submit
        >
          {isLoading ? (
            <Spinner className={S.spinner} />
          ) : (
            msg(login_createAccount)
          )}
        </Button>
      </>
    );
  }
);
