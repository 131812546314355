'use client';

import { useRouter } from '@/react/utils/router-utils/useRouter';
import { FunctionComponent } from 'react';
import { Button } from '../Button';
import S from './styles.base.module.scss';

/**
 * An object that holds the content and metadata for a specific Tab in the {@link Tabs} component.
 */
export interface ITabsContent {
  /**
   * The title that is going to be displayed in the clickable tab on top.
   */
  title: string;
  /**
   * Each title has its corresponding FunctionComponent that is going to be rendered when is clicked.
   */
  component: FunctionComponent;
  /**
   * Function that will get called when the user clicks on an specific tab.
   */
  onClick: () => void;
}

export interface ITabsProps {
  /**
   * An array of TabsContent that allows navigation between groups of content.
   */
  tabsContent: Array<ITabsContent>;
}

/**
 * Tabs Component.
 *
 *
 * @example ```tsx
 * const tabsContent = [
 * {
 *  title: 'Tab 1',
 *  component: ContentOne,
 *  onClick: () => {}
 * },
 * {
 *  title: 'Tab 2',
 *  component: ContentTwo,
 *  onClick: () => {}
 * }
 *];
 * ```
 *  *
 * @example ```tsx
 * <Tabs tabsContent={tabsContent} />
 * ```
 */
export const Tabs: FunctionComponent<ITabsProps> = ({ tabsContent }) => {
  const router = useRouter();
  const activeTabIndex = Number(router.query.tab) || 0;

  return (
    <div className={S.wrapper}>
      <div className={S.tabs} role="tablist">
        {tabsContent.map((tab, index) => {
          const ariaLabel = `Tab ${index} - ${tab.title}`;
          return (
            <Button
              className={`${S.tab} ${activeTabIndex === index && S.activeTab}`}
              key={tab.title}
              role="tab"
              id={`tab${index}`}
              aria-label={ariaLabel}
              aria-selected={activeTabIndex === index}
              variant="text"
              onClick={tab.onClick}
              onKeyPress={(e) => {
                const keyEvent = e as unknown as KeyboardEvent;
                if (keyEvent.code === 'Enter') {
                  tab.onClick();
                }
              }}
            >
              {tab.title}
            </Button>
          );
        })}
      </div>
      <div className={S.tabsWrapper}>
        {tabsContent.map((tab, index) => {
          if (activeTabIndex === index) {
            return (
              <div
                className={S.tabsBody}
                role="tabpanel"
                id={`tab${index}`}
                key={tab.title}
              >
                <tab.component />
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};
