'use client';

import { FunctionComponent } from 'react';

import { CheckOrderFormModel } from '@/react/view-models/forms/CheckOrder';
import { msg } from '@/services/isomorphic/I18NService';
import { useFormContext } from '../../../../hooks/useFormContext';
import { Button } from '../../../core-ui/Button';
import { TextField } from '../../../core-ui/TextField';

import S from './styles.module.scss';
import { useGlobalContext } from '@/react/hooks/useGlobalContext';
import { order_checkOrder_emailOrOrderLabel } from "@/lang/__generated__/ahnu/order_checkOrder_emailOrOrderLabel";
import { order_checkOrder_billingZipCode } from "@/lang/__generated__/ahnu/order_checkOrder_billingZipCode";
import { order_checkOrder_checkStatus } from "@/lang/__generated__/ahnu/order_checkOrder_checkStatus";

export interface ICheckOrderFormFieldsProps {
  /** If the form button should be in a loading state. */
  loading?: boolean;
}

/**
 * CheckOrderFormFields Component.
 * This component holds the actual form field components and the submit button.
 */
const CheckOrderFormFields: FunctionComponent<ICheckOrderFormFieldsProps> = ({
  loading = false
}) => {
  const { user } = useGlobalContext();
  const { form, isLoading } = useFormContext(CheckOrderFormModel);

  return (
    <>
      <TextField
        className={S.input}
        label={msg(order_checkOrder_emailOrOrderLabel)}
        fullWidth
        fieldName="emailOrOrderID"
        datatestID="ordEmail"
        formModel={form}
      />
      <TextField
        className={S.input}
        label={msg(order_checkOrder_billingZipCode)}
        fullWidth
        fieldName="zipCode"
        datatestID="ordZipCode"
        type="zip"
        formModel={form}
      />

      <Button
        loading={loading || !user}
        variant="secondary"
        fullWidth
        className={S.submitButton}
        disabled={isLoading}
        datatestID="ordSubmit"
        submit
      >
        {msg(order_checkOrder_checkStatus)}
      </Button>
    </>
  );
};

export default CheckOrderFormFields;
