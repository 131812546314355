import {
  minLength,
  nonEmpty,
  onlyLetters,
  validEmail,
  validPhoneNumber
} from '@/react/view-models/Form/validation-utils';
import { msg } from '@/services/isomorphic/I18NService';
import { DTO } from '@/type-utils';
import { makeObservable, observable } from 'mobx';
import { FormModel, validatable } from '../../Form';
import { forms_field_firstName } from "@/lang/__generated__/ahnu/forms_field_firstName";
import { forms_field_lastName } from "@/lang/__generated__/ahnu/forms_field_lastName";
import { forms_field_email } from "@/lang/__generated__/ahnu/forms_field_email";
import { forms_field_password } from "@/lang/__generated__/ahnu/forms_field_password";
import { forms_field_phone } from "@/lang/__generated__/ahnu/forms_field_phone";
import { forms_field_emailList } from "@/lang/__generated__/ahnu/forms_field_emailList";

/**
 * Describes the fields of the Create Account form. {@see {@link CreateAccountForm}}.
 */
export interface ICreateAccountForm {
  /**
   * User's first name.
   */
  firstName: string;
  /**
   * User's last name.
   */
  lastName: string;
  /**
   * User's email.
   */
  email: string;
  /**
   * User's password.
   */
  password: string;
  /**
   * User's phone.
   */
  phone: string;
  /**
   * Checkbox that can be toggled if they want to subscribed to the email list.
   */
  emailList: boolean;
}

/** @inheritdoc */
export class CreateAccountFormModel
  extends FormModel<ICreateAccountForm>
  implements ICreateAccountForm
{
  /** @inheritdoc */
  @observable
  @validatable({
    get name() {
      return msg(forms_field_firstName);
    },
    validations: [nonEmpty, onlyLetters, minLength(2)]
  })
  public firstName: string;

  /** @inheritdoc */
  @observable
  @validatable({
    get name() {
      return msg(forms_field_lastName);
    },
    validations: [nonEmpty, onlyLetters, minLength(2)]
  })
  public lastName: string;

  /** @inheritdoc */
  @observable
  @validatable({
    get name() {
      return msg(forms_field_email);
    },
    validations: [validEmail]
  })
  public email: string;

  /** @inheritdoc */
  @observable
  @validatable({
    get name() {
      return msg(forms_field_password);
    },
    validations: []
  })
  public password: string;

  /** @inheritdoc */
  @observable
  @validatable({
    get name() {
      return msg(forms_field_phone);
    },
    validations: [validPhoneNumber]
  })
  public phone: string;

  /** @inheritdoc */
  @observable
  @validatable({
    get name() {
      return msg(forms_field_emailList);
    },
    validations: []
  })
  public emailList: boolean;

  /** @inheritdoc */
  public constructor(dto: DTO<ICreateAccountForm>) {
    super(dto);
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
    this.email = dto.email;
    this.password = dto.password;
    this.phone = dto.phone;
    this.emailList = dto.emailList;

    makeObservable(this);
  }

  /** @inheritdoc */
  public toDTO(): DTO<ICreateAccountForm> {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      password: this.password,
      phone: this.phone,
      emailList: this.emailList
    };
  }
}
