'use client';

import { useGlobalContext } from '@/react/hooks/useGlobalContext';
import { useRouter } from '@/react/utils/router-utils/useRouter';
import { CheckOrderFormModel } from '@/react/view-models/forms/CheckOrder';
import { msg } from '@/services/isomorphic/I18NService';
import LoggerService from '@/services/isomorphic/LoggerService';
import OrderLookupService, {
  UnableToFindOrderError
} from '@/services/isomorphic/OrderLookupService';
import { IOrderLookupParams } from '@/services/models/OrderHistory';
import { InvalidArgumentError, InvalidOperationError } from '@/utils/errors';
import { isNullOrEmpty } from '@/utils/null-utils';
import { FunctionComponent, useState, useTransition } from 'react';
import { Alert } from '../../core-ui/Alert';
import { Form } from '../../core-ui/Form';
import CheckOrderFormFields from './CheckOrderFormFields';

import S from './styles.module.scss';
import { returns_errors_doesNotMatchRecords } from "@/lang/__generated__/ahnu/returns_errors_doesNotMatchRecords";
import { returns_errors_somethingWentWrong } from "@/lang/__generated__/ahnu/returns_errors_somethingWentWrong";
import { order_checkOrder_title } from "@/lang/__generated__/ahnu/order_checkOrder_title";
import { order_checkOrder_prompt } from "@/lang/__generated__/ahnu/order_checkOrder_prompt";

/**
 * CheckOrder Component.
 * This component allows the user see the status of their order, even if they don't have an account.
 */
const CheckOrder: FunctionComponent = () => {
  const router = useRouter();
  const { user } = useGlobalContext();
  const [form] = useState(
    () =>
      new CheckOrderFormModel({
        emailOrOrderID: '',
        zipCode: ''
      })
  );

  const [isNavigationPending, startRouteTransition] = useTransition();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);

    if (!user) {
      throw new Error('User must exist to check order status.');
    }

    try {
      const { email, orderID } = OrderLookupService.collapseEmailOrOrderID(
        form.emailOrOrderID
      );

      const params: IOrderLookupParams = {
        email,
        orderID,
        zipCode: form.zipCode,
        userUUID: user.uuid
      };

      const paramsOK = await OrderLookupService.validateParams(params);

      if (!paramsOK) {
        throw new InvalidOperationError(
          'Cannot look up orders: You must provide either an email + a zipCode' +
            ' OR an orderID + zipCode for anonymous lookup operations.'
        );
      }

      const searchParams = new URLSearchParams();
      const lookupParams: IOrderLookupParams = { userUUID: user.uuid };

      if (!isNullOrEmpty(orderID)) lookupParams.orderID = orderID;
      if (!isNullOrEmpty(email)) lookupParams.email = email;
      if (form.zipCode) lookupParams.zipCode = form.zipCode;

      const lookupID = OrderLookupService.saveLookupParams(lookupParams);

      searchParams.set('lookupid', lookupID);

      // Look the order up...
      const { totalItems } = await OrderLookupService.getOrderHistory({
        params: {
          ...lookupParams,
          lookupID
        }
      });

      if (totalItems === 0) {
        throw new UnableToFindOrderError(
          'An unexpected error occurred when looking up orders.'
        );
      }

      startRouteTransition(() => {
        router.push(`/orders?${searchParams.toString()}`);
      });
    } catch (error) {
      if (
        error instanceof InvalidArgumentError ||
        error instanceof UnableToFindOrderError
      ) {
        setError(msg(returns_errors_doesNotMatchRecords));
      } else {
        setError(msg(returns_errors_somethingWentWrong));
        LoggerService.error(error as Error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={S.wrapper}>
      <div className={S.title}>{msg(order_checkOrder_title)}</div>
      <div className={S.body}>
        <Alert visible={Boolean(error)} variant="error">
          {error}
        </Alert>
        <div className={S.description}>{msg(order_checkOrder_prompt)}</div>
        <Form form={form} submit={handleSubmit}>
          <CheckOrderFormFields loading={isLoading || isNavigationPending} />
        </Form>
      </div>
    </div>
  );
};

export default CheckOrder;
