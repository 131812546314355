import {
  minLength,
  nonEmpty,
  onlyNumeric
} from '@/react/view-models/Form/validation-utils';
import { msg } from '@/services/isomorphic/I18NService';
import { DTO } from '@/type-utils';
import { makeObservable, observable } from 'mobx';
import { FormModel, validatable } from '../../Form';
import { forms_field_emailOrOrder } from "@/lang/__generated__/ahnu/forms_field_emailOrOrder";
import { forms_address_zipCode } from "@/lang/__generated__/ahnu/forms_address_zipCode";

/**
 * Describes the fields of the Check Order form. {@see {@link CheckOrder}}.
 */
export interface ICheckOrderForm {
  /**
   * User's email or an order ID.
   */
  emailOrOrderID: string;

  /**
   * User's zip code.
   */
  zipCode: string;
}

/** @inheritdoc */
export class CheckOrderFormModel
  extends FormModel<ICheckOrderForm>
  implements ICheckOrderForm
{
  /** @inheritdoc */
  @observable
  @validatable({
    get name() {
      return msg(forms_field_emailOrOrder);
    },
    validations: [nonEmpty]
  })
  public emailOrOrderID: string;

  /** @inheritdoc */
  @observable
  @validatable({
    get name() {
      return msg(forms_address_zipCode);
    },
    validations: [onlyNumeric, minLength(5)]
  })
  public zipCode: string;

  /** @inheritdoc */
  public constructor(dto: DTO<ICheckOrderForm>) {
    super(dto);
    this.emailOrOrderID = dto.emailOrOrderID;
    this.zipCode = dto.zipCode;

    makeObservable(this);
  }

  /** @inheritdoc */
  public toDTO(): DTO<ICheckOrderForm> {
    return {
      emailOrOrderID: this.emailOrOrderID,
      zipCode: this.zipCode
    };
  }
}
