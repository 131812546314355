'use client';

import { observer } from 'mobx-react-lite';
import { ReactElement, useEffect, useState } from 'react';

import I18NService from '@/services/isomorphic/I18NService';

import { IForm } from '@/react/view-models/Form';
import { formatIncompletePhoneNumber } from '@/services/utils/phone-utils';
import { InvalidPhoneNumberValueError } from '@/services/utils/phone-utils/errors';
import { ITextFieldProps, TextField } from '..';

export interface IPhoneFieldProps<T extends IForm> extends ITextFieldProps<T> {
  /**
   * If `true`, the values passed to this phone field will be masked.
   *
   * The mask will follow the number's locale format, which will be
   * determined automatically from the value as it is entered.
   *
   * Defaults to `true`.
   */
  enableMasking?: boolean;
}

/** Extends {@link TextField} by adding phone-number specific features.  */
export const PhoneField = observer(function PhoneField<T extends IForm>(
  props: IPhoneFieldProps<T>
) {
  const { formModel, fieldName, enableMasking = true } = props;
  const passedValue = formModel[fieldName as keyof T];

  if (typeof passedValue !== 'string') {
    throw new InvalidPhoneNumberValueError(
      'Phone field got a non-string value'
    );
  }

  const [value, setValue] = useState<string>(passedValue);
  const [previousValue, setPreviousValue] = useState<string>(passedValue);

  useEffect(() => {
    // If masking is disabled, or if the newer value is shorted than the previous value
    // (i.e. the user is removing characters)...
    if (!enableMasking || passedValue.length < previousValue.length) {
      // Only assign the value.
      setValue(passedValue);
    } else {
      // Else, format the value before assigning it.
      setValue(
        formatIncompletePhoneNumber(
          passedValue,
          I18NService.currentLocale.country
        )
      );
    }

    // Always update the previous value at the end.
    setPreviousValue(passedValue);
  }, [passedValue]);

  return <TextField {...props} type="tel" value={value} isInInputMask />;
});
