import { FunctionComponent } from 'react';
import { IBreadcrumb } from '@/services/models/Breadcrumb';
import { Link } from '../../core-ui/Link';

import S from './styles.module.scss';

export interface IBreadcrumbsProps {
  /** Breadcrumb items that contain a link and a display value. */
  breadcrumbs: ReadonlyArray<IBreadcrumb>;
}

/**
 * Displays a group of links in a row separated by slashes.
 */
export const Breadcrumbs: FunctionComponent<IBreadcrumbsProps> = ({
  breadcrumbs
}) => {
  const lastCrumb = breadcrumbs.length - 1;
  return (
    <div className={S.breadcrumbs}>
      {breadcrumbs.map((breadcrumb, i) => {
        const ending = lastCrumb === i;
        return (
          <span key={breadcrumb.displayValue}>
            <Link
              className={S.breadcrumb}
              variant="text"
              href={breadcrumb.link}
              key={breadcrumb.link}
            >
              {breadcrumb.displayValue}
            </Link>
            {!ending ? ' / ' : ''}
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
