'use client';

import { useRouter } from '@/react/utils/router-utils/useRouter';
import { msg } from '@/services/isomorphic/I18NService';
import { observer } from 'mobx-react-lite';
import { ITabsContent, Tabs } from '../../core-ui/Tabs';
import { CreateAccountForm } from '../CreateAccountForm';
import { LoginForm } from '../LoginForm';

import S from './styles.module.scss';
import { login_title } from "@/lang/__generated__/ahnu/login_title";
import { login_createAccount } from "@/lang/__generated__/ahnu/login_createAccount";

/**
 * Login Create
 * This component is the wrapper that contains two components:
 * LoginForm and CreateAccountForm.
 */
const LoginCreateAccount = observer(function LoginCreateAccount() {
  const router = useRouter();

  const tabsContent: Array<ITabsContent> = [
    {
      title: msg(login_title),
      component: LoginForm,
      onClick: () => {
        router.replace(`/login?tab=0`);
      }
    },
    {
      title: msg(login_createAccount),
      component: CreateAccountForm,
      onClick: () => {
        router.replace(`/login?tab=1`);
      }
    }
  ];

  return (
    <div className={S.wrapper}>
      <Tabs tabsContent={tabsContent} />
    </div>
  );
});

export default LoginCreateAccount;
