'use client';

import { useRouter } from '@/react/utils/router-utils/useRouter';
import { FunctionComponent, useCallback, useState } from 'react';

import { CreateAccountFormModel } from '@/react/view-models/forms/CreateAccount';
import { ISignUpCredentials } from '@/services/models/User/AuthenticatedUser';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import I18NService, { msg } from '@/services/isomorphic/I18NService';
import LoggerService from '@/services/isomorphic/LoggerService';
import UserService, {
  UserAccountAlreadyExistsError
} from '@/services/isomorphic/UserService';

import { parsePhoneNumber } from '@/services/utils/phone-utils';
import { Nullable } from '@/type-utils';

import { useClearFormsOnNavigation } from '@/react/hooks/useClearFormsOnNavigation';
import { useGlobalContext } from '@/react/hooks/useGlobalContext';
import EmailService from '@/services/isomorphic/EmailService';
import UserInteractionService, {
  EventType
} from '@/services/isomorphic/UserInteractionService';
import { Alert } from '../../core-ui/Alert';
import { Form } from '../../core-ui/Form';
import { CreateAccountFormFields } from './CreateAccountFormFields';
import { account_error_usernameAlreadyTaken } from "@/lang/__generated__/ahnu/account_error_usernameAlreadyTaken";
import { account_error_unknownError } from "@/lang/__generated__/ahnu/account_error_unknownError";

/**
 * A form the user can fill up to create an account.
 */
export const CreateAccountForm: FunctionComponent = () => {
  const router = useRouter();
  const { cart } = useGlobalContext();

  const [form] = useState(
    () =>
      new CreateAccountFormModel({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phone: '',
        emailList: true
      })
  );

  const [alertContent, setAlertContent] = useState<Nullable<string>>(null);

  useClearFormsOnNavigation(form);

  const handleSubmit = useCallback(async (): Promise<void> => {
    setAlertContent(null);

    try {
      const redirectURL = ConfigurationService.getConfig('user').getSetting(
        'signUpRedirectLocation'
      ).value;

      const { email, password, firstName, lastName, phone, emailList } = form;

      const creationCredentials: ISignUpCredentials = {
        email,
        password,
        firstName,
        lastName,
        phoneNumber: phone
          ? parsePhoneNumber(phone, I18NService.currentLocale.country)
          : null
      };

      // Sign the user up (and automatically log them in)...
      const user = await UserService.signUp(creationCredentials);
      await cart.tryMergeWithUserCart(user);

      // only sign them up for marketing emails if they opted-in and
      // the account sign-up was successful
      if (emailList) {
        try {
          await EmailService.setCustomerPreferences({
            email,
            locale: I18NService.currentLocale.toString(),
            receivePromotionalEmails: true
          });

          UserInteractionService.makeAction({
            action: EventType.SignupSuccess,
            customer_email: email
          });
        } catch (err) {
          // Add event to GTM
          UserInteractionService.makeAction({ action: EventType.SignupError });
          // If marketing email opt-in failed for some reason, proceed as
          // normal since the account was already created, but log the error.
          // There is no use in displaying the error because we navigate away.
          LoggerService.error(err as Error);
        }
      }

      // And redirect to the configured URL.
      router.push(redirectURL);
    } catch (error) {
      // Add event to GTM
      UserInteractionService.makeAction({ action: EventType.LoginError });
      if (error instanceof UserAccountAlreadyExistsError) {
        setAlertContent(msg(account_error_usernameAlreadyTaken));
      } else {
        setAlertContent(msg(account_error_unknownError));
        LoggerService.error(error as Error);
      }
    }
  }, [cart, form, router]);

  return (
    <Form
      submit={handleSubmit}
      form={form}
      enterInteractionDetails={{
        action: EventType.FormInput,
        formName: 'create account'
      }}
      submitInteractionDetails={{
        action: EventType.LoginRegister
      }}
    >
      <Alert variant="error" visible={Boolean(alertContent)}>
        {alertContent}
      </Alert>

      <CreateAccountFormFields />
    </Form>
  );
};
