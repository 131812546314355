'use client';

import { FunctionComponent, useMemo } from 'react';

import CheckOrder from '../../account/CheckOrder';
import LoginCreateAccount from '../../account/LoginCreateAccount';
import { Breadcrumbs } from '../../navigation/Breadcrumbs/Breadcrumbs';

import S from './styles.module.scss';

/**
 * LoginPage Component
 * This component wraps the LoginPageCreateAccount and CheckOrder components in order to get them stylized properly.
 * When visiting the /loginPage page this is what the user will see.
 */
const LoginPage: FunctionComponent = () => {
  const breadcrumbs = useMemo(
    () => [
      {
        displayValue: 'Home',
        link: '/'
      },
      {
        displayValue: 'Login',
        link: '/login'
      }
    ],
    []
  );

  return (
    <div className={S.loginPage}>
      <div className={S.breadCrumbs}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </div>
      <div className={S.container}>
        <div className={S.wrapper}>
          <LoginCreateAccount />
          <CheckOrder />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
